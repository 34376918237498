'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import uuidGen from 'uuid';

import RecipeEditor from './Editor.react';
import { isValidURL } from '../../utils/Util';
import Alert from '../Widgets/Alert/Alert.react';

import { getConfig } from '../../utils/Env';
import Analytics from '../../utils/Analytics';
import { getRecipeEventProperties } from '../../utils/Content';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import platform from 'platform';

import './AddRecipe.scss';

export default class AddRecipe extends Component {
    static propTypes = {
        profile: PropTypes.object,
        closeModal: PropTypes.func,
    };

    static contextTypes = {
        onSelectRecipe: PropTypes.func,
        addSwapContext: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            mode: 'import',
            working: false,
            recipeUrl: null,
            recipe: null,
            details: null,
            error: null,
            importFailure: false,
            importSuccess: false,
            importErrorMessage: null,
            editSessionId: uuidGen.v4().substring(0, 8),
        };

        this.importInputRef = createRef();
    }

    setCreateMode = () => {
        this.setState({ mode: 'create' });
    };

    setImportMode = () => {
        this.setState({ mode: 'import' });
    };

    onChangeRecipeUrl = (ev) => {
        this.setState({ recipeUrl: ev.target.value.trim(), importErrorMessage: null });
    };

    onSaveRecipe = (recipe) => {
        const { closeModal, onSaveRecipe } = this.props;

        if (onSaveRecipe) {
            onSaveRecipe(recipe);
        }

        this.setState({ recipe: recipe });
        closeModal && closeModal();
    };

    validateRecipeUrl = () => {
        const { recipeUrl } = this.state;
        if (!isValidURL(recipeUrl)) {
            this.setState({ importErrorMessage: 'Please enter a valid URL or website link.' });
            this.importInputRef.current.focus();
            return false;
        } else {
            this.setState({ importErrorMessage: null });
            return true;
        }
    };

    onClickImportRecipe = (ev) => {
        ev.preventDefault();
        if (this.validateRecipeUrl()) {
            this.importRecipe({ url: this.state.recipeUrl }, 'import/recipe/url/1');
        }
    };

    importRecipe = async (body, schema) => {
        const { editSessionId } = this.state;

        this.setState({ working: 'importing', delay: false, error: false, importSuccess: false, importFailure: false });
        let timeoutId = setTimeout(() => this.setState({ delay: true }), 15000);

        let response;

        try {
            response = await AuthStore.fetch(getConfig('recipe_api') + '/import-recipe', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; schema=' + schema },
                body: JSON.stringify(body),
            });
        } catch (exp) {
            Analytics.importRecipeError({
                error: exp.message,
                'Edit Session ID': editSessionId,
                ...body,
            });

            this.setState({
                working: false,
                delay: false,
                error: exp.message || 'unknown error',
                importFailure: true,
                mode: 'create',
            });
            return;
        }

        Analytics.startImportRecipe({
            ...body,
            'Edit Session ID': editSessionId,
            'Job ID': response.uuid,
        });

        let jobUrl = getConfig('recipe_api') + response?.links.self.href;
        let job = await AuthStore.fetch(jobUrl);
        let governor = 300;

        while (job?.status !== 'completed' && job?.status !== 'failed' && governor-- > 0) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            job = await AuthStore.fetch(jobUrl);
        }

        if (job?.status !== 'completed') {
            Analytics.importRecipeError({
                error: job.output?.error,
                'Job ID': job?.uuid,
                'Edit Session ID': editSessionId,
                ...body,
                governor,
            });

            this.setState({
                working: false,
                delay: false,
                error: job?.output?.error || 'unknown error',
                importFailure: true,
                importSuccess: false,
                mode: 'create',
            });
            return;
        }

        const { recipe, details } = job?.output;

        recipe.owner = UserStore.getUser().uuid;

        this.setState({
            working: false,
            delay: false,
            error: false,
            recipe: recipe,
            details: details,
            importSuccess: true,
            importFailure: false,
            mode: 'create',
        });

        Analytics.importRecipeSuccess({
            ...body,
            ...getRecipeEventProperties(recipe, details),
            'Job ID': job?.uuid,
            'Edit Session ID': editSessionId,
        });

        clearTimeout(timeoutId);
    };

    renderRecipeEditor = () => {
        const { profile, closeModal } = this.props;
        const { mode, error, importSuccess, importFailure, recipe, details, editSessionId } = this.state;

        if (mode !== 'create') {
            return;
        }

        return (
            <RecipeEditor
                profile={profile}
                closeModal={closeModal}
                onSaveRecipe={this.onSaveRecipe}
                importSuccess={importSuccess}
                importFailure={importFailure}
                recipe={recipe}
                details={details}
                editSessionId={editSessionId}
                savedOwnRecipeContext="Add/Swap"
            />
        );
    };

    render() {
        const { mode, working, delay, recipeUrl, recipe } = this.state;
        const { profile } = this.props;

        return (
            <div className="add-recipe-container" data-has-toggles={!recipe ? true : false}>
                {!recipe ? (
                    <>
                        <div className="toggle-btn-group">
                            <button data-active={mode === 'import'} onClick={this.setImportMode}>
                                Import Recipe
                            </button>
                            <button data-active={mode === 'create'} onClick={this.setCreateMode}>
                                Add Manually
                            </button>
                        </div>

                        {mode == 'import' || working ? (
                            <div className="add-recipe-form">
                                {mode == 'import' ? (
                                    <div className="el-labeled-input">
                                        <label>Automatically Import Recipe Using a Website Link</label>
                                        {!working ? (
                                            <input
                                                type="text"
                                                placeholder="Paste the full recipe URL here (e.g., https://example.com/recipe)"
                                                ref={this.importInputRef}
                                                onChange={this.onChangeRecipeUrl}
                                            />
                                        ) : null}
                                        {this.state.importErrorMessage && (
                                            <div className="error-container">
                                                <Alert type="warning" description={this.state.importErrorMessage} />
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                                {working ? (
                                    <div className="loading-container">
                                        <i className="icon-spinner2" />
                                        <span className="loading-text">
                                            {delay
                                                ? 'Please wait while we import your recipe. This can take up to a minute.'
                                                : 'Please wait while we import your recipe'}
                                        </span>
                                    </div>
                                ) : null}

                                {!working && mode == 'import' ? (
                                    <footer>
                                        <button
                                            className="el-medium-btn el-raspberry-btn"
                                            onClick={this.onClickImportRecipe}
                                        >
                                            import
                                        </button>
                                        <button
                                            className="el-link-btn"
                                            data-active={mode === 'create'}
                                            onClick={this.setCreateMode}
                                        >
                                            Add Recipe Manually
                                        </button>
                                    </footer>
                                ) : null}
                            </div>
                        ) : null}
                    </>
                ) : null}
                {this.renderRecipeEditor()}
            </div>
        );
    }
}
