'use strict';

import React from 'react';

const serializeText = (element) => {
    try {
        if (React.isValidElement(element) && typeof element.props.children?.join === 'function') {
            return element?.props?.children ? element?.props?.children?.join(' ') : '';
        }

        return element;
    } catch (e) {
        return element;
    }
};

export default serializeText;
