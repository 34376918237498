'use strict'

// This file was generated by chewba/bin/import-conditions.php on 2024-09-19
// See https://docs.google.com/spreadsheets/d/1n9F2hYa5W62W1TmosDSa8wolUxzNZGTTXWlEif_NFd4/edit#gid=614672165 to make changes
// bin/import-conditions.php -s '1n9F2hYa5W62W1TmosDSa8wolUxzNZGTTXWlEif_NFd4' -c 'Conditions' -d 'Demographics' | tee docs/js/tables/conditions.js

export default [
    {
        "demographics": {
            "0-18-yr": {
                "min_age": 0,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "0-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "418": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.20 \/ 9",
                        "max": "kcal * 0.30 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "418": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "B12 Deficient Anemia",
        "on_pro": true,
        "on_consumer": true,
        "icon": "silverware",
        "position": 90,
        "background": "Based on adult Dietary Reference Intake (DRI) for B12.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Vitamin B12 deficiency",
        "name": "Anemia B12 Deficient"
    },
    {
        "demographics": {
            "0-18-yr": {
                "min_age": 0,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "0-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "303": {
                        "min": "dri"
                    },
                    "401": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.20 \/ 9",
                        "max": "kcal * 0.30 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "303": {
                        "min": "dri"
                    },
                    "401": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "Iron Deficient Anemia",
        "on_pro": true,
        "on_consumer": true,
        "icon": "chard",
        "position": 80,
        "background": "Anemia Iron Deficient\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Iron deficiency",
        "name": "Anemia Iron Deficient"
    },
    {
        "demographics": {
            "0-18-yr": {
                "min_age": 0,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "0-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [
            "gluten"
        ],
        "on_pro": true,
        "on_consumer": true,
        "icon": "gluten-free",
        "position": 50,
        "background": "Provides recipes with ingredients that are naturally gluten free.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Managing celiac disease\n- Avoidances include: gluten, wheat, rye, barley",
        "name": "Celiac Disease"
    },
    {
        "demographics": {
            "19-60-yr": {
                "min_age": 19,
                "max_age": 60
            },
            "61+yr": {
                "min_age": 61
            }
        },
        "templates": {
            "4-13-yr": {
                "all-day": {
                    "203": {
                        "min": "0.95 * weight_kg",
                        "max": "0.95 * weight_kg * 2"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "305": {
                        "max": "dri"
                    },
                    "306": {
                        "max": "dri"
                    },
                    "307": {
                        "max": "1500"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "14-18-yr": {
                "all-day": {
                    "203": {
                        "min": "0.85 * weight_kg",
                        "max": "0.85 * weight_kg * 2"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "305": {
                        "max": "dri"
                    },
                    "306": {
                        "max": "dri"
                    },
                    "307": {
                        "max": "1500"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "19-60-yr": {
                "all-day": {
                    "203": {
                        "min": "0.6 * weight_kg",
                        "max": "0.8 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "max": "1000"
                    },
                    "306": {
                        "max": "4000"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "max": "dri"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "61+yr": {
                "all-day": {
                    "203": {
                        "min": "0.6 * weight_kg",
                        "max": "0.8 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "min": "800",
                        "max": "1000"
                    },
                    "306": {
                        "max": "4000"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "max": "dri"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal",
            "dri"
        ],
        "avoidances": [
            "phosphorus"
        ],
        "premium": true,
        "on_pro": true,
        "consumer_name": "CKD Friendly",
        "on_consumer": true,
        "background": "Provides a plan with limits on \nprotein, phosphorus, sodium and potassium while emphasising vegetables.\n<br \/><br \/> \n**Recommended for:** \n<br \/><br \/>\n- Managing stage 1 or 2 chronic kidney disease",
        "name": "CKD Stage 1-2"
    },
    {
        "demographics": {
            "19-60-yr": {
                "min_age": 19,
                "max_age": 60
            },
            "61+yr": {
                "min_age": 61
            }
        },
        "templates": {
            "19-60-yr": {
                "all-day": {
                    "203": {
                        "max": "0.6 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "305": {
                        "max": "1000"
                    },
                    "306": {
                        "max": "3500"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "61+yr": {
                "all-day": {
                    "203": {
                        "max": "0.6 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "305": {
                        "min": "800",
                        "max": "1000"
                    },
                    "306": {
                        "max": "3500"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal",
            "dri"
        ],
        "avoidances": [
            "phosphorus"
        ],
        "consumer_name": "CKD Friendly",
        "on_pro": true,
        "background": "Provides a plan with more strict limits on \nprotein, phosphorus, sodium and potassium while emphasising a plant-based diet.\n<br \/><br \/> \n**Recommended for:** \n<br \/><br \/>\n- Managing stage 3-4 chronic kidney disease",
        "name": "CKD Stage 3-4"
    },
    {
        "demographics": {
            "19-60-yr": {
                "min_age": 19,
                "max_age": 60
            },
            "61+yr": {
                "min_age": 61
            }
        },
        "templates": {
            "19-60-yr": {
                "all-day": {
                    "203": {
                        "max": "0.6 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "max": "1000"
                    },
                    "306": {
                        "max": "3500"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "61+yr": {
                "all-day": {
                    "203": {
                        "max": "0.6 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "min": "800",
                        "max": "1000"
                    },
                    "306": {
                        "max": "3500"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal",
            "dri"
        ],
        "avoidances": [
            "phosphorus"
        ],
        "consumer_name": "CKD Friendly",
        "on_pro": true,
        "background": "Provides a plan with more strict limits on \nprotein, phosphorus, sodium and potassium while emphasising a plant-based diet.\n<br \/><br \/> \n**Recommended for:** \n<br \/><br \/>\n- Managing stage 5 chronic kidney disease",
        "name": "CKD Stage 5"
    },
    {
        "demographics": {
            "19-60-yr": {
                "min_age": 19,
                "max_age": 60
            },
            "61+yr": {
                "min_age": 61
            }
        },
        "templates": {
            "19-60-yr": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg",
                        "max": "1.2 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "min": "800",
                        "max": "1000"
                    },
                    "307": {
                        "max": "2300"
                    }
                }
            },
            "61+yr": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg",
                        "max": "1.2 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "min": "800",
                        "max": "1000"
                    },
                    "306": {
                        "max": "2400"
                    },
                    "307": {
                        "max": "2300"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal"
        ],
        "avoidances": [
            "phosphorus"
        ],
        "consumer_name": "CKD Friendly",
        "on_pro": true,
        "background": "Provides a plan with more strict limits on \nprotein, phosphorus, sodium and potassium while emphasising a plant-based diet.\n<br \/><br \/> \n**Recommended for:** \n<br \/><br \/>\n- Managing stage 5 ESRD on hemodialysis",
        "name": "CKD Stage 5\/HD"
    },
    {
        "demographics": {
            "19-60-yr": {
                "min_age": 19,
                "max_age": 60
            },
            "61+yr": {
                "min_age": 61
            }
        },
        "templates": {
            "19-60-yr": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg",
                        "max": "1.2 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "min": "800",
                        "max": "1000"
                    },
                    "307": {
                        "max": "2300"
                    }
                }
            },
            "61+yr": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg",
                        "max": "1.2 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "800",
                        "max": "1000"
                    },
                    "305": {
                        "min": "800",
                        "max": "1000"
                    },
                    "306": {
                        "max": "2400"
                    },
                    "307": {
                        "max": "2300"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal"
        ],
        "avoidances": [
            "phosphorus"
        ],
        "consumer_name": "CKD Friendly",
        "on_pro": true,
        "background": "Provides a plan with more strict limits on \nprotein, phosphorus, sodium and potassium while emphasising a plant-based diet.\n<br \/><br \/> \n**Recommended for:** \n<br \/><br \/>\n- Managing stage 5 ESRD on peritoneal dialysis",
        "name": "CKD Stage 5\/PD"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "max": "15"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "Recommends a diet lower in fiber.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Active diverticulitis flare-ups",
        "name": "Diverticulitis"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "High Fiber",
        "special_name": "Increasing Fiber",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "icon": "apple",
        "background": "Recommends a diet normal in fiber with set targets for fruits and vegetables.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Periods between active diverticulitis flare-ups",
        "name": "Diverticulosis"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "1.1 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "icon": "heart3",
        "background": "Recommended dietary pattern set by the American Heart Association (AHA) with increased protein, and <2300 mg Na\/day.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients or clients diagnosed with heart failure",
        "name": "Heart Failure"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "30"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "kcal * 0.07 \/ 9"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "high-cholesterol",
        "position": 20,
        "background": "Based on the dietary patterns set by the Dietary Guidelines for Americans with more fiber and limits on trans, saturated fat and <2300 mg Na\/day.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients or clients diagnosed with high cholesterol",
        "name": "High Cholesterol"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "606": {
                        "max": "kcal * 0.07 \/ 9"
                    },
                    "645": {
                        "min": "kcal * 0.10 \/ 9"
                    },
                    "646": {
                        "min": "kcal * 0.10 \/ 9"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "icon": "high-cholesterol",
        "name": "High Triglycerides"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "1000"
                    },
                    "304": {
                        "min": "dri"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "min": "2",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "3"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "Bone Health",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "icon": "osteoporosis",
        "position": 100,
        "background": "A general healthy plan with additional targets on protein, calcium and magnesium.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients or clients diagnosed with or looking to prevent osteoporosis",
        "name": "Osteoporosis"
    },
    {
        "demographics": {
            "0-18-yr": {
                "min_age": 0,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            },
            "0-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "Lose Weight",
        "special_name": "Weight Management",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "lose-weight",
        "position": 10,
        "background": "Based on the dietary patterns set by the Dietary Guidelines for Americans with additional limits for saturated fat.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients or patients overweight or obese by BMI standards",
        "name": "Overweight\/Obesity"
    },
    {
        "demographics": {
            "1-3-yr": {
                "min_age": 1,
                "max_age": 3
            },
            "4-18-yr": {
                "min_age": 4,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "1-3-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.10 \/ 4",
                        "max": "kcal * 0.20 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "kcal \/ 1000 * 14"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "25.2"
                    }
                },
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.45 \/ 4 * 0.20",
                        "max": "kcal * 0.55 \/ 4 * 0.30"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.45 \/ 4 * 0.20",
                        "max": "kcal * 0.55 \/ 4 * 0.30"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.45 \/ 4 * 0.20",
                        "max": "kcal * 0.55 \/ 4 * 0.30"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.55 \/ 4 * 0.30"
                    }
                }
            },
            "4-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "kcal \/ 1000 * 14"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "25.2"
                    }
                },
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            },
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "1"
                    },
                    "VEG": {
                        "min": "4"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                },
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "VEG": {
                        "min": "2"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "VEG": {
                        "min": "2"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "diabetes1",
        "position": 40,
        "background": "Based on the dietary patterns set by the Dietary Guidelines for Americans and allows RD to control carbs per meal.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Insulin dependent (type 1) patients or clients",
        "name": "Diabetes Type 1"
    },
    {
        "demographics": {
            "1-3-yr": {
                "min_age": 1,
                "max_age": 3
            },
            "4-18-yr": {
                "min_age": 4,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "1-3-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.10 \/ 4",
                        "max": "kcal * 0.20 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "kcal \/ 1000 * 14"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "25.2"
                    }
                },
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.45 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.45 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.45 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            },
            "4-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "kcal \/ 1000 * 14"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "25.2"
                    }
                },
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            },
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "1"
                    },
                    "VEG": {
                        "min": "4"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                },
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "VEG": {
                        "min": "2"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "VEG": {
                        "min": "2"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "diabetes",
        "position": 40,
        "background": "Based on the dietary patterns by the Dietary Guidelines for Americans and allows RD to control carbs per meal.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Insulin resistent (type 2) patients or clients",
        "name": "Diabetes Type 2"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "name": "Lipid Metabolism Disorder"
    },
    {
        "demographics": {
            "14-18-yr": {
                "min_age": 14,
                "max_age": 18,
                "gender": "female"
            },
            "19+yr": {
                "min_age": 19,
                "gender": "female"
            }
        },
        "templates": {
            "14-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "500"
                    },
                    "303": {
                        "min": "18"
                    },
                    "435": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "500"
                    },
                    "303": {
                        "min": "18"
                    },
                    "435": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [
            "unsafe-for-pregnancy"
        ],
        "update_method": "pregnancy",
        "on_pro": true,
        "on_consumer": true,
        "icon": "mother-child",
        "position": 60,
        "background": "Dietary plan adaptable for due date with greater total calories and additional focus on fiber, calcium, iron and folate. For higher iron needs, adjust the minimum directly in your clients nutrition rx.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Expectant mothers",
        "name": "Pregnancy"
    },
    {
        "demographics": {
            "14-18-yr": {
                "min_age": 14,
                "max_age": 18,
                "gender": "female"
            },
            "19+yr": {
                "min_age": 19,
                "gender": "female"
            }
        },
        "templates": {
            "14-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "500"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            },
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "500"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "Breast Feeding",
        "on_pro": true,
        "on_consumer": true,
        "icon": "baby-bottle",
        "position": 70,
        "background": "A plan with increased calories to support lactation with additional calcium targets.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Women who are breastfeeding",
        "name": "Lactation"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "70"
                    },
                    "204": {
                        "max": "50"
                    },
                    "205": {
                        "max": "145"
                    },
                    "208": {
                        "min": "980",
                        "max": "1350"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                },
                "Breakfast": {
                    "204": {
                        "max": "16"
                    },
                    "208": {
                        "min": "150",
                        "max": "300"
                    }
                },
                "Lunch": {
                    "208": {
                        "min": "200",
                        "max": "300"
                    }
                },
                "Dinner": {
                    "208": {
                        "min": "200",
                        "max": "300"
                    }
                },
                "Snack": []
            }
        },
        "parameters": [
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "Low-moderate calorie diet with recommended meal components for a balanced plate.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Post-op bariatric patients when able to transition to whole foods",
        "name": "Bariatric Stage 4"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "OLF": {
                        "max": "2"
                    },
                    "OLG": {
                        "max": "2"
                    },
                    "FRC": {
                        "max": "2"
                    },
                    "LAC": {
                        "max": "2"
                    },
                    "MAN": {
                        "max": "2"
                    },
                    "SOR": {
                        "max": "2"
                    }
                },
                "Snack": {
                    "OLF": {
                        "max": "0.5"
                    },
                    "OLG": {
                        "max": "0.5"
                    },
                    "FRC": {
                        "max": "0.5"
                    },
                    "LAC": {
                        "max": "0.5"
                    },
                    "MAN": {
                        "max": "0.5"
                    },
                    "SOR": {
                        "max": "0.5"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "Dietary plan set up as an elimination diet for high FODMAP foods - Has elimination and reintroduction feature.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients or clients dealing with gastrointestinal problems stemming from IBS\n- Elimination diet",
        "name": "Low FODMAP"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "600"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "min": "3",
                        "max": "5"
                    },
                    "VEG": {
                        "min": "5"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "HLF": {
                        "min": "1"
                    }
                },
                "Breakfast": {
                    "FRU": {
                        "min": "0.9",
                        "max": "2"
                    }
                },
                "Lunch": {
                    "FRU": {
                        "min": "0.6",
                        "max": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "High Blood Pressure",
        "on_pro": true,
        "on_consumer": true,
        "icon": "heart3",
        "position": 30,
        "background": "Supports a DASH style diet with added calcium and <2300 mg Na\/day targets.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients or clients diagnosed with hypertension",
        "name": "Hypertension"
    },
    {
        "demographics": {
            "0-18-yr": {
                "min_age": 0,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            },
            "0-18-yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "special_name": "A Healthy Diet",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "silverware3",
        "position": 0,
        "background": "Based on the healthy dietary patterns set by the Dietary Guidelines for Americans including recommended fruit and vegetable intake.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Maintaining current health or reducing risk of disease\n- Weight Management\n- A balanced plate",
        "name": "General Healthy Diet"
    },
    {
        "demographics": {
            "0-18-yr": {
                "min_age": 0,
                "max_age": 18
            },
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            },
            "0-18-yr": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "special_name": "Healthy Flex",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "plate3",
        "position": 90,
        "background": "Based on the healthy dietary patterns set by the Dietary Guidelines for Americans including recommended fruit and vegetable intake. Calorie targets only - no macros.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients or patients with a lot of food allergies and other restrictions\n- Allows for maximum meal variety and flexibility (including the same lunch and dinner)",
        "name": "Healthy Flex"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "clamp(kcal * 0.10 \/ 9, 0, 25)"
                    },
                    "FRU": {
                        "min": "1",
                        "max": "3"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "carrot",
        "position": 35,
        "background": "Based on the dietary patterns set by the Dietary Guidelines for Americans with limits on sodium, added sugar and saturated fat\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients or patients with labs levels that fall within the prediabetes range",
        "name": "Prediabetes"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "1500"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "special_name": "Decreasing Sodium",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "icon": "salt",
        "background": "Supports a DASH style diet with <1500 mg Na\/day - ADA targets for fruit and vegetables.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients or clients looking to consume less sodium daily",
        "name": "Low Sodium"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "3"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "special_name": "Increasing Vegetables",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "bellpepper2",
        "background": "Based on the dietary patterns set by the Dietary Guidelines for Americans.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Getting more veggies in one's daily diet",
        "name": "Eat More Veggies"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4"
                    },
                    "205": {
                        "max": "100"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "avocado1",
        "background": "Recommends a lower carb plan with a limit of <100g per day.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients who want to follow a low-carb plan\n- Manage weight\"",
        "name": "Low Carb"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "special_name": "Increasing Energy",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "reduce-inflamation",
        "background": "Based on the dietary patterns set by the Dietary Guidelines for Americans with fruit, vegetable and macro targets.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients or patients with a goal to increase energy",
        "name": "Increase Energy"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.10 \/ 4",
                        "max": "kcal * 0.20 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.35 \/ 9",
                        "max": "kcal * 0.45 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "301": {
                        "min": "1000"
                    },
                    "307": {
                        "max": "2800"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "max": "3"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "A diet moderate in protein with minimum calcium targets and 2800 mg Na\/day.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patient diagnosed with hypermetabolism",
        "name": "Hypermetabolism"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "barbell",
        "background": "Provides a plan higher in carbohydrates - adjustable macro targets.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Individuals or athletes that engage in regular endurance based exercise",
        "name": "Endurance"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.25 \/ 4",
                        "max": "kcal * 0.35 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.35 \/ 4",
                        "max": "kcal * 0.45 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "muscles",
        "background": "Slightly lower carb, with higher fat and protein - adjustable macro targets.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Individuals or athletes that engage in regular strength based exercise",
        "name": "Muscle Gain"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.25 \/ 4",
                        "max": "kcal * 0.35 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.35 \/ 9",
                        "max": "kcal * 0.45 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.25 \/ 4",
                        "max": "kcal * 0.35 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "logo3",
        "background": "Recommends a low-moderate carb plan with higher fat - macros are adjustable.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients who want to follow a low-carb plan\n- Manage weight\"",
        "name": "Low Carb\/High Fat"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "208": {
                        "min": "kcal - 100",
                        "max": "kcal + 100"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [
            "non_whole30_sugar",
            "alcohol",
            "grains",
            "non_whole30_legumes",
            "dairy",
            "chocolate",
            "processed-meats",
            "oils-highly-processed",
            "non_whole30_additives"
        ],
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "strawberry",
        "position": 20,
        "background": "Total elimination of Whole30 foods for 30 days with adjustable macro targets.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients participating in Whole30\n- Elimination diet\"",
        "name": "Whole30"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "208": {
                        "min": "kcal - 100",
                        "max": "kcal + 100"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [
            "grains",
            "legumes",
            "soy",
            "nightshades",
            "eggs",
            "dairy",
            "treenuts",
            "seeds",
            "alcohol",
            "coffee",
            "oils-highly-processed",
            "sweetners",
            "herbs"
        ],
        "icon": "silverware3",
        "name": "Auto Immune Protocol"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.25 \/ 4",
                        "max": "kcal * 0.35 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.35 \/ 4",
                        "max": "kcal * 0.45 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "FRU": {
                        "min": "dri",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "logo3",
        "position": 99,
        "background": "Slightly lower carb, with higher end targets of fat and protein with an emphasis on fruit and veggies-adjustable macro targets.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients for looking to reduce inflammation\n- Weight Management\"",
        "name": "Zone Diet"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "204": {
                        "max": "kcal * 0.27 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "dri"
                    },
                    "304": {
                        "min": "dri"
                    },
                    "306": {
                        "min": "2300"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "kcal *0.06 \/ 9"
                    },
                    "FRU": {
                        "min": "3",
                        "max": "5"
                    },
                    "VEG": {
                        "min": "4"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "HLF": {
                        "min": "1"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "DASH Diet",
        "on_pro": true,
        "on_consumer": true,
        "icon": "GLP-1",
        "background": "DASH stands for Dietary Approaches to Stop Hypertension. This plan is rich in \nvegetables, fruits and whole grains. It has targets to limit fat and sodium while getting \nample fiber, calcium and magnesium.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Individuals who need more precise guidelines on fat and sodium and managing \nheart conditions or hypertension",
        "name": "DASH Diet"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "HLF": {
                        "min": "2.4"
                    },
                    "LPR": {
                        "min": "2.4"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "Mediterranean Diet",
        "on_pro": true,
        "on_consumer": true,
        "lifestyle": true,
        "trainer": true,
        "icon": "olive-branch",
        "background": "Dietary plan that resembles eating patterns of the countries that border the Mediterranean Sea. A primarily plant-based way of eating including vegetables, fruits, nuts, seeds, beans, legumes, whole grains, extra-virgin olive oil, herbs and spices, plus fatty fish as the core framework with dairy and meat in moderation.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Individuals wanting to follow general healthy diet plan or managing chronic conditions like diabetes, certain cancers, cognitive decline, and heart conditions",
        "name": "Mediterranean Diet"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "HLF": {
                        "min": "2.4"
                    },
                    "WHG": {
                        "min": "2"
                    },
                    "NUT": {
                        "min": "1"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "consumer_name": "MIND Diet",
        "on_pro": true,
        "on_consumer": true,
        "icon": "brain",
        "background": "MIND stands for Mediterranean-DASH Intervention for Neurodegenerative Delay diet \nand blends a primarily plant-based diet from the Med with fat and sodium restrictions \nfrom the DASH diet.\n<br \/><br \/> \n**Recommended for:** \n<br \/><br \/>\n- Individuals wanting to follow general healthy diet plan or managing chronic \nconditions like diabetes, certain cancers, cognitive decline and Alzheimer's\n- Individuals who need more precise guidelines on fat and sodium and managing \nheart conditions or hypertension",
        "name": "MIND Diet"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "204": {
                        "min": "kcal * 0.70 \/ 9",
                        "max": "kcal * 0.80 \/ 9"
                    },
                    "205": {
                        "min": "0",
                        "max": "kcal * 0.10 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "VEG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "This is a low-carb, standard Keto style diet with < 10% of the diet coming from carbs. Macro targets are adjustable. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Indivuduals working with a medical professional or dietitian who need very low carb settings to manage epilepsy",
        "name": "Keto"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19,
                "gender": "female"
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.15 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 4 * 0.20",
                        "max": "kcal * 0.35 \/ 4 * 0.30"
                    },
                    "205": {
                        "min": "kcal * 0.45 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "28"
                    },
                    "301": {
                        "min": "500"
                    },
                    "303": {
                        "min": "18"
                    },
                    "435": {
                        "min": "600"
                    },
                    "FRU": {
                        "min": "2",
                        "max": "4"
                    },
                    "VEG": {
                        "min": "3"
                    }
                },
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            }
        },
        "parameters": [
            "kcal"
        ],
        "avoidances": [
            "unsafe-for-pregnancy"
        ],
        "update_method": "pregnancy",
        "icon": "mother-child",
        "position": 40,
        "background": "Based on the dietary patterns by the Dietary Guidelines for Americans and allows RD to control carbs per meal.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Expectant mothers diagnosed with gestational diabetes",
        "name": "Gestational Diabetes"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "HLF": {
                        "min": "2.4"
                    },
                    "LPR": {
                        "min": "2.4"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "trainer": true,
        "icon": "reduce-inflamation",
        "background": "Dietary plan that consists primarily of plant-based \nfoods including vegetables, fruits, nuts, seeds, \nbeans, legumes, whole grains, extra-virgin olive oil, \nherbs and spices, and fatty fish.\n<br \/><br \/> \n**Recommended for:**\n <br \/><br \/> \n- Individuals wanting to reduce overall inflammation\nwith plant-based foods\n- For more specific food avoidances (ex. meat, dairy,\n etc..) we recommend adding avoidance tags in \nyour clients' taste preferences section\n- Definition of this diet will vary between client needs \nand practicioner",
        "name": "Anti-Inflammatory"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.35 \/ 9",
                        "max": "kcal * 0.45 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.30 \/ 4",
                        "max": "kcal * 0.40 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "trainer": true,
        "icon": "soy",
        "background": "Based on the dietary patterns set by the Dietary \nGuidelines for Americans for protein and fiber.\n<br \/><br \/> **Recommended for:** \n<br \/><br \/> \n- Individuals wanting to promote weight loss\n- Individuals wanting to preserve lean muscle\n- Improving satiety and reducing energy crashes",
        "name": "High Fiber \/ High Protein"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "500"
                    },
                    "303": {
                        "min": "18"
                    },
                    "324": [],
                    "401": {
                        "min": "75"
                    },
                    "435": {
                        "min": "400"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "OM3": [],
                    "421": {
                        "min": "425"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "female-sign",
        "background": "A fertility friendly plan that supports moderate protein and healthy fats as well as micronutrients such as folate, iron, calcium, choline, and more to support a healthy conception. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Women trying to conceive",
        "name": "Fertility"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "500"
                    },
                    "303": {
                        "min": "18"
                    },
                    "324": [],
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "OM3": [],
                    "421": {
                        "min": "425"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "mother-child",
        "background": "A postpartum plan to support mothers after birth. Nutrients targeted include moderate protein, omega-3's, iron, calcium, choline, and more. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Women who are postpartum",
        "name": "Postpartum"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "303": {
                        "min": "18"
                    },
                    "401": {
                        "min": "75"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "OM3": [],
                    "430": {
                        "min": "90"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "female",
        "background": "A plan to support women during the \nMenstrual Phase of their Menstrual Cycle. \nNutrients targeted include: iron, vitamin C, vitamin K,  \nhealthy fats and more. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Women in the Menstrual Phase of their Menstrual Cycle \n(Days 0 to 7: This is when you have your period.)",
        "name": "Menstrual Phase"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "female",
        "background": "A plan to support women during the Follicular and Ovulatory Phase of their Menstrual Cycle. Focusing on lean protein, complex carbohydrates and nutrients to balance estrogen levels.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Women in the Follicular and Ovulatory Phase of their Menstrual Cycle (Days 8 to 15)",
        "name": "Follicular & Ovulation Phase"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "1000"
                    },
                    "304": {
                        "min": "320"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "404": {
                        "min": "1.1"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "415": {
                        "min": "1.3"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "female",
        "background": "A plan to support women during the Luteal Phase of \ntheir Menstrual Cycle. Nutrients targeted include B \nvitamins, calcium and magnesium, fiber, complex \ncarbohydrates and more. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Women in the Luteal Phase of their Menstrual Cycle \n(Days 16-28)",
        "name": "Luteal Phase"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "606": {
                        "max": "dri"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "WHG": {
                        "min": "2"
                    },
                    "BER": [],
                    "NUT": []
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "trainer": true,
        "icon": "eat-more-plants",
        "background": "This plans supports gut health through foods high in polyphenols: a plant compound that has been shown to boost digestion and protect against various diseases. Some well-known sources of polyphenols include: berries, cherries, artichokes, beans, legumes, nuts, seeds, whole grains, herbs and spices. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Clients in the process of healing their gut\n-  Individuals who want to support their gut through food",
        "name": "Microbiome Booster"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "1000"
                    },
                    "304": {
                        "min": "320"
                    },
                    "324": [],
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "430": {
                        "min": "90"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "female",
        "background": "A menopausal plan to support women during menopause. Nutrients targeted include protein, fiber, calcium, vitamin D, vitamin K, magnesium and more. \n\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Menopausal women",
        "name": "Menopause"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.40 \/ 9"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "1000"
                    },
                    "304": {
                        "min": "320"
                    },
                    "324": [],
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "430": {
                        "min": "90"
                    },
                    "WHG": {
                        "min": "2"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "icon": "female",
        "background": "A post menopausal plan to support women post-menopause. Nutrients targeted include protein, fiber, calcium, vitamin D, vitamin K, magnesium and more. \n\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Post-menopausal women",
        "name": "Post Menopause"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.20 \/ 4",
                        "max": "kcal * 0.30 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal *0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "30"
                    },
                    "307": {
                        "max": "2000"
                    },
                    "605": {
                        "max": "kcal * 0.01 \/ 9"
                    },
                    "606": {
                        "max": "kcal * 0.07 \/ 9"
                    },
                    "FRU": {
                        "min": "1"
                    },
                    "VEG": {
                        "min": "4"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "WHG": {
                        "min": "2"
                    }
                }
            },
            "19+yr": {
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "VEG": {
                        "min": "2"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "VEG": {
                        "min": "2"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "Based on the Dietary \nGuidelines for Americans and allows the provider to \ncontrol carbs per meal with more fiber and limited \namounts of trans, saturated fat and sodium.\n<br \/><br \/> \n**Recommended for:** \n<br \/><br \/> \n- Patients managing cardiovascular disease and \ntype 2 diabetes",
        "position": 40,
        "name": "Cardiovascular Disease + Diabetes"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.25 \/ 4",
                        "max": "kcal * 0.35 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.35 \/ 4",
                        "max": "kcal * 0.45 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "307": {
                        "max": "2000"
                    },
                    "606": {
                        "max": "kcal * 0.10 \/ 9"
                    },
                    "FRU": {
                        "max": "3"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "WHG": {
                        "min": "3"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "A dietary plan to help manage non-alcoholic steatohepatitis (NASH). Focusing fruits, vegetables and whole grains while limiting added sugar, sodium and saturated fat.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n-  Patients diagnosed with and managing NASH",
        "name": "NASH"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal *0.25 \/ 4",
                        "max": "kcal * 0.35 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.25 \/ 9",
                        "max": "kcal * 0.35 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.35 \/ 4",
                        "max": "kcal * 0.45 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "307": {
                        "max": "2000"
                    },
                    "606": {
                        "max": "kcal * 0.10 \/ 9"
                    },
                    "FRU": {
                        "max": "3"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "WHG": {
                        "min": "3"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "A dietary plan to help manage Non-alcoholic fatty liver diesease (NAFLD). Focusing fruits, vegetables and whole grains while limiting added sugar, sodium and saturated fat.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients diagnosed with and managing NAFLD",
        "name": "NAFLD"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg",
                        "max": "1.5 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "304": [],
                    "307": {
                        "max": "2000"
                    },
                    "324": [],
                    "401": [],
                    "404": [],
                    "606": {
                        "max": "kcal * 0.10 \/ 9"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "430": [],
                    "309": {
                        "min": "dri"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "A dietary plan to help manage Cirrhosis. Focusing healthy protein intake and limiting added sugar, sodium and saturated fat. Increased calories may be recommended unless overweight and dx with NASH.\n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients diagnosed with and managing cirrhosis",
        "name": "Cirrhosis"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "304": {
                        "min": "dri"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "435": {
                        "min": "400"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "WHG": {
                        "min": "3"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "background": "A plan to help manage and reduce the frequency of migraines focusing on an anti-inflammatory dietary pattern with adequate amounts of Magnesium, Vitamin B2 and Folate. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Patients or clients prone to migraines\n- Individauls who want to follow an anti-inflammatory pattern of eating",
        "name": "Migraine"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19,
                "gender": "female"
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "205": {
                        "min": "kcal * 0.35 \/ 4",
                        "max": "kcal * 0.45 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri"
                    },
                    "301": {
                        "min": "1000"
                    },
                    "304": {
                        "min": "320"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "324": [],
                    "606": {
                        "max": "kcal * 0.10 \/ 9"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    },
                    "ASG": {
                        "max": "dri"
                    },
                    "OM3": [],
                    "309": {
                        "min": "8"
                    },
                    "WHG": {
                        "min": "3"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "background": "A dietary pattern to help manage and reduce symptoms of Polycystic Ovarian Syndrome (PCOS), an endocrine condition that can cause multiple ovarian cysts, abnormal hair growth, inflammation, and more. This plan is rooted in Mediterranean diet principles including fruits, vegetables, whole grains, and nutrients like Omega-3's, calcium, and zinc. Recommend to adjust carbohydrate % if patient is insulin resistant. \n<br \/><br \/>\n**Recommended for:**\n<br \/><br \/>\n- Women diagnosed with and managing PCOS\n- Women experiencing insulin resistance or inflammation",
        "name": "PCOS"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg",
                        "max": "1.65 * weight_kg"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "kcal * 0.10 \/ 9"
                    },
                    "FRU": {
                        "min": "2"
                    },
                    "VEG": {
                        "min": "3"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal"
        ],
        "avoidances": [],
        "on_pro": true,
        "on_consumer": true,
        "background": "A general healthy plan focused on protein and fiber\nwhile limiting added sugar and saturated fat. \n<br \/><br \/> \n**Recommended for:**\n <br \/><br \/> \n- Individuals taking GLP-1 medication looking for healthy\nweight loss.",
        "name": "GLP-1 Weight Loss"
    },
    {
        "demographics": [],
        "templates": {
            "default": {
                "all-day": {
                    "203": {
                        "min": "1.0 * weight_kg",
                        "max": "1.65 * weight_kg"
                    },
                    "205": {
                        "min": "kcal * 0.40 \/ 4",
                        "max": "kcal * 0.50 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "25"
                    },
                    "307": {
                        "max": "2300"
                    },
                    "606": {
                        "max": "kcal * 0.10 \/ 9"
                    },
                    "FRU": {
                        "min": "1"
                    },
                    "VEG": {
                        "min": "4"
                    },
                    "ASG": {
                        "max": "dri"
                    }
                }
            },
            "19+yr": {
                "Breakfast": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Lunch": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Dinner": {
                    "205": {
                        "min": "kcal * 0.40 \/ 4 * 0.20",
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    },
                    "ASG": {
                        "max": "dri \/ 3"
                    }
                },
                "Snack": {
                    "205": {
                        "max": "kcal * 0.50 \/ 4 * 0.30"
                    }
                }
            }
        },
        "parameters": [
            "weight_kg",
            "kcal",
            "dri"
        ],
        "avoidances": [],
        "on_pro": true,
        "background": "Based on the dietary patterns by the Dietary Guidelines for \nAmericans and allows the provider to control carbs per meal \nwhile limiting added sugar and saturated fat. \n<br \/><br \/> \n**Recommended for:**\n <br \/><br \/> \n- Individuals taking GLP-1 medication to manage type 2 diabetes",
        "name": "GLP-1 Diabetes Management"
    },
    {
        "demographics": {
            "19+yr": {
                "min_age": 19
            }
        },
        "templates": {
            "19+yr": {
                "all-day": {
                    "203": {
                        "min": "kcal * 0.05 \/ 4",
                        "max": "kcal * 0.25 \/ 4"
                    },
                    "204": {
                        "min": "kcal * 0.30 \/ 9",
                        "max": "kcal * 0.50 \/ 9"
                    },
                    "205": {
                        "min": "kcal * 0.35 \/ 4",
                        "max": "kcal * 0.55 \/ 4"
                    },
                    "208": {
                        "min": "kcal",
                        "max": "kcal"
                    },
                    "291": {
                        "min": "dri * 0.90"
                    },
                    "301": {
                        "min": "700"
                    },
                    "303": {
                        "min": "dri * 0.90"
                    },
                    "304": {
                        "min": "dri * 0.90"
                    },
                    "305": {
                        "min": "dri * 0.90"
                    },
                    "306": {
                        "min": "dri * 0.90"
                    },
                    "401": {
                        "min": "dri * 0.90"
                    },
                    "435": {
                        "min": "dri * 0.90"
                    },
                    "418": {
                        "min": "dri * 0.90"
                    },
                    "323": {
                        "min": "dri * 0.90"
                    },
                    "VEG": {
                        "max": "9"
                    },
                    "OMA": [],
                    "675": [],
                    "415": {
                        "min": "dri * 0.90"
                    },
                    "430": {
                        "min": "dri * 0.90"
                    },
                    "309": {
                        "min": "dri * 0.90"
                    },
                    "317": {
                        "min": "dri * 0.90"
                    }
                }
            }
        },
        "parameters": [
            "kcal",
            "dri"
        ],
        "avoidances": [
            "processed-meats"
        ],
        "on_pro": true,
        "name": "Extensive Macro\/Micro"
    }
];
